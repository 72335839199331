export function buildQueryString(data: object): string {
    const validTypes = ["string", "number", "boolean"];
    return Object.keys(data)
        .filter((key) => validTypes.indexOf(typeof data[key]) !== -1)
        .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
}
